import React, { useEffect } from 'react';

import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
  DrawerHeader,
  DrawerFooter,
  useDisclosure,
  Stack,
  Flex,
} from '@chakra-ui/react';

import theme from '@/theme/_generated';

import { SiteSettingsType } from '@/api';
import { navigationLinks } from '@/constants';
import { Image, Link } from '@/theme/chakra-elements';
import {
  ButtonLogin,
  ButtonQuickExit,
  ButtonRegister,
  ButtonSearch,
} from '@/components/ui';
import LogoImageSVG from '@/images/amica-logo-dark.svg';

import {
  Navigation,
  NavigationLink,
  InfoHeader,
  InfoHeaderLabel,
  InfoHeaderLink,
  ButtonHamburger,
  ButtonClose,
  MobileNavigationLink,
  MobileInfoHeaderLink,
  StickyBox,
  AlertAnnouncement,
} from './HeaderComponents';

import { Router } from 'next/router';

type HeaderProps = { siteSettings: SiteSettingsType };

const Header: React.FC<HeaderProps> = ({ siteSettings = {} }) => {
  const {
    preheaderEmergency,
    preheaderEmergencyLinkText,
    preheaderEmergencyLink,
    preheaderCrisisLine,
    preheaderCrisisLineLinkText,
    preheaderCrisisLineLink,
    preheaderLifeline,
    preheaderLifelineLink,
    preheaderLifelineLinkText,
    loginLinkText,
    loginLink,
    registerLinkText,
    registerLink,
    alert,
  } = siteSettings;

  const { header: headerData } = navigationLinks;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const contacts = [
    {
      label: preheaderEmergency,
      link: preheaderEmergencyLink,
      linkText: preheaderEmergencyLinkText,
      id: 'emergency',
    },
    {
      label: preheaderCrisisLine,
      link: preheaderCrisisLineLink,
      linkText: preheaderCrisisLineLinkText,
      id: 'crisisLine',
    },
    {
      label: preheaderLifeline,
      link: preheaderLifelineLink,
      linkText: preheaderLifelineLinkText,
      id: 'lifeLine',
    },
  ];

  useEffect(() => {
    // Close Mobile Drawer if open on next route change
    Router.events.on('routeChangeComplete', () => {
      if (isOpen) {
        onClose();
      }
    });
  }, [onClose, isOpen]);

  return (
    <>
      <StickyBox>
        <InfoHeader
          display={{ base: 'none', md: 'flex' }}
          py={2}
          data-gtm-id="info-header"
        >
          <Stack direction="row" alignItems="center" spacing={10}>
            {contacts.map((contact) => (
              <Flex alignItems="baseline" key={contact.id}>
                <InfoHeaderLabel id={contact.id}>
                  {contact.label}
                </InfoHeaderLabel>
                <InfoHeaderLink
                  href={contact.link}
                  aria-labelledby={contact.id}
                >
                  {contact.linkText}
                </InfoHeaderLink>
              </Flex>
            ))}
          </Stack>
          <Stack direction="row" alignItems="center">
            <ButtonQuickExit />
          </Stack>
        </InfoHeader>
        <Navigation
          zIndex={2}
          data-gtm-id="navigation"
          boxShadow={{ base: theme.shadows.card, md: theme.shadows.layer }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={6}
            display={{ base: 'flex', prexl: 'none' }}
          >
            <ButtonHamburger onClick={onOpen} flexShrink={0} />
            <Box>
              <NavigationLink
                href="/"
                data-gtm-id="header-logo-link"
                aria-label="Home Link"
              >
                <Image
                  src={LogoImageSVG}
                  height={20}
                  width={74}
                  priority
                  alt=""
                  quality={100}
                />
              </NavigationLink>
            </Box>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={5}
            display={{ base: 'none', prexl: 'flex' }}
          >
            <NavigationLink
              href="/"
              data-gtm-id="header-logo-link"
              aria-label="Home Link"
              mr={3}
            >
              <Image
                src={LogoImageSVG}
                height={20}
                width={74}
                priority
                alt=""
                quality={100}
              />
            </NavigationLink>
            {headerData.navigation.map(({ text, url }) => (
              <NavigationLink key={text} href={url}>
                {text}
              </NavigationLink>
            ))}
            <ButtonSearch />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            display={{ base: 'none', md: 'flex' }}
          >
            <ButtonLogin color="custom.primary.700" href={loginLink}>
              {loginLinkText}
            </ButtonLogin>
            <ButtonRegister href={registerLink}>
              {registerLinkText}
            </ButtonRegister>
          </Stack>
          <ButtonQuickExit display={{ base: 'block', md: 'none' }} />
        </Navigation>
        <AlertAnnouncement {...alert} />
      </StickyBox>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="full">
        <DrawerOverlay />
        <DrawerContent
          data-gtm-id="mobile-drawer-content"
          backgroundColor="#FFFFFF"
        >
          <DrawerHeader padding={6}>
            <Flex as={Flex} width="100%" justifyContent="space-between">
              <Stack direction="row" spacing={4}>
                <ButtonClose padding={4} onClick={onClose} />
                <Link href="/" data-gtm-id="mobile-navigation-link">
                  <Image
                    alt=""
                    src={LogoImageSVG}
                    height={20}
                    width={75}
                    priority
                    quality={100}
                  />
                </Link>
              </Stack>
              <ButtonQuickExit />
            </Flex>
          </DrawerHeader>
          <DrawerBody paddingX={6} overflowY="auto">
            <Stack>
              {headerData.navigation.map(({ text, url }) => (
                <MobileNavigationLink key={text} href={url}>
                  {text}
                </MobileNavigationLink>
              ))}
              <Box paddingY={3}>
                <ButtonSearch marginBottom={16} />
                <Stack direction="row" alignItems="center" spacing={2}>
                  <ButtonRegister href={registerLink}>
                    {registerLinkText}
                  </ButtonRegister>
                  <ButtonLogin
                    color="custom.primary.700"
                    padding={0}
                    href={loginLink}
                  >
                    {loginLinkText}
                  </ButtonLogin>
                </Stack>
              </Box>
            </Stack>
          </DrawerBody>
          <DrawerFooter
            as={InfoHeader}
            py={{ base: 6, md: 10 }}
            paddingInline={0}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={{ base: 4, sm: 8 }}
            >
              {contacts.map((contact) => (
                <Stack key={contact.id} spacing={2} height="50px">
                  <InfoHeaderLabel id={contact.id}>
                    {contact.label}
                  </InfoHeaderLabel>
                  <MobileInfoHeaderLink
                    href={contact.link}
                    aria-labelledby={contact.id}
                  >
                    {contact.linkText}
                  </MobileInfoHeaderLink>
                </Stack>
              ))}
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;
