import React from 'react';

import { Button } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import type { ButtonProps } from '@chakra-ui/react';
import { runQuickExit } from '@/utils/helpers';

export type Props = ButtonProps;

const CURRENT_TAB_URL = 'https://google.com';
const NEW_TAB_URL = 'http://www.bom.gov.au';

const ButtonQuickExit: React.FC<Props> = ({
  children = 'Quick exit',
  ...props
}) => {
  return (
    <Button
      colorScheme="amica-destructive"
      leftIcon={<CloseIcon width={'12px'} height={'12px'} />}
      letterSpacing="0.01em"
      onClick={() =>
        runQuickExit({
          currentTabUrl: CURRENT_TAB_URL,
          newTabUrl: NEW_TAB_URL,
        })
      }
      data-gtm-id="button-quick-exit"
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonQuickExit;
