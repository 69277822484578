import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Text,
  Container,
  ButtonProps,
  Button,
  IconButton,
} from '@chakra-ui/react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link } from '@/theme/chakra-elements';
import type { LinkProps, TextProps, FlexProps } from '@chakra-ui/react';
import { AlertProps, Markdown } from '@/components/ui';

const StickyBox: React.FC<FlexProps> = ({ children, ...rest }) => (
  <Box position="sticky" top="0" zIndex={2} {...rest}>
    {children}
  </Box>
);

const Navigation: React.FC<FlexProps> = ({ children, ...rest }) => (
  <Flex
    as="nav"
    width="100%"
    backgroundColor="custom.neutral.25"
    layerStyle="header.navigation"
    {...rest}
  >
    <Container maxWidth="container.xl">
      <Flex
        width="100%"
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        flexShrink={0}
        alignContent="center"
      >
        {children}
      </Flex>
    </Container>
  </Flex>
);

const NavigationLink: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => (
  <Link
    textStyle="Website/paragraph-small-bold"
    variant="navigation"
    color="custom.primary.700"
    href={href}
    data-gtm-id="navigation-link"
    {...rest}
  >
    {children}
  </Link>
);

const MobileNavigationLink: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => (
  <Flex borderBottomWidth={1} borderColor="custom.neutral.100">
    <Link
      textStyle="Website/paragraph-bold"
      variant="navigation"
      pt={2}
      pb={4}
      width="100%"
      href={href}
      data-gtm-id="mobile-navigation-link"
      {...rest}
    >
      {children}
    </Link>
  </Flex>
);

const InfoHeader: React.FC<FlexProps> = ({ children, ...rest }) => (
  <Flex
    as="nav"
    backgroundColor="custom.neutral.800"
    width="100%"
    position="sticky"
    top="0"
    zIndex="100"
    layerStyle="header.infoHeader"
    {...rest}
  >
    <Container maxWidth="container.xl">
      <Flex
        width="100%"
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        flexShrink={0}
        alignContent="center"
      >
        {children}
      </Flex>
    </Container>
  </Flex>
);

const InfoHeaderLabel: React.FC<TextProps> = ({ children, ...rest }) => (
  <Text as="label" textStyle="label" marginRight={1} color="#FFFFFF" {...rest}>
    {children}
  </Text>
);

const InfoHeaderLink: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => (
  <Link
    textStyle="Website/paragraph-small"
    href={href}
    color="#FFFFFF"
    fontWeight="bold"
    variant="navigation"
    data-gtm-id="info-header-link"
    _hover={{ textDecoration: 'underline' }}
    {...rest}
  >
    {children}
  </Link>
);

const MobileInfoHeaderLink: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => (
  <Link
    textStyle="Website/paragraph-small-bold"
    color="#FFFFFF"
    textDecorationLine="underline"
    textUnderlineOffset="3px"
    href={href}
    data-gtm-id="mobile-info-header-link"
    _hover={{ color: 'custom.primary.50' }}
    {...rest}
  >
    {children}
  </Link>
);

const ButtonHamburger: React.FC<ButtonProps> = ({ ...props }) => (
  <IconButton
    aria-label="hamburger"
    colorScheme="amica-purple"
    color="white"
    width="48px"
    height="48px"
    icon={<HamburgerIcon width={5} height={5} />}
    size="icon"
    isRound
    data-gtm-id="button-hamburger"
    {...props}
  />
);

const ButtonClose: React.FC<ButtonProps> = ({ ...props }) => (
  <IconButton
    aria-label="close"
    colorScheme="amica-purple"
    color="white"
    icon={<CloseIcon width={3} height={3} />}
    size="icon"
    isRound
    data-gtm-id="button-close"
    {...props}
  />
);

const AlertAnnouncement: React.FC<AlertProps> = ({
  active,
  text,
  buttonText,
}) => {
  const alertText = text || '';
  const [alertDismissed, setAlertDismissed] = useState<string | null>();

  useEffect(() => {
    setAlertDismissed(localStorage.getItem('alert-dismissed'));
  }, []);

  const handleDismiss = () => {
    localStorage.setItem('alert-dismissed', 'true');
    setAlertDismissed(localStorage.getItem('alert-dismissed'));
  };

  return active && !alertDismissed ? (
    <Flex background="custom.neutral.25" paddingTop={4} boxShadow="sm">
      <Container maxW="container.xl">
        <Flex
          justify="space-between"
          alignItems="center"
          wrap="wrap"
          gap="12px"
        >
          <Box mb={4} mt={4}>
            <Markdown body={alertText} />
          </Box>
          <Button
            mb={4}
            colorScheme="amica-grey"
            rightIcon={<CloseIcon width={'12px'} height={'12px'} />}
            variant="solid"
            size="small"
            padding={3}
            paddingY={2}
            onClick={handleDismiss}
            flexShrink={0}
            data-gtm-id="button-announcement-alert"
          >
            {buttonText}
          </Button>
        </Flex>
      </Container>
    </Flex>
  ) : null;
};

export {
  StickyBox,
  Navigation,
  NavigationLink,
  MobileNavigationLink,
  InfoHeader,
  MobileInfoHeaderLink,
  InfoHeaderLabel,
  InfoHeaderLink,
  ButtonHamburger,
  ButtonClose,
  AlertAnnouncement,
};
