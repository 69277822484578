import React from 'react';

import { Search2Icon } from '@chakra-ui/icons';

import type { ButtonProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';

export type Props = ButtonProps;

const ButtonSearch: React.FC<Props> = ({ children = 'Search', ...props }) => {
  return (
    <LinkButton
      colorScheme="amica-grey"
      rightIcon={<Search2Icon />}
      letterSpacing="0.01em"
      px={4}
      href="/search"
      data-gtm-id="button-search"
      {...props}
    >
      {children}
    </LinkButton>
  );
};

export default ButtonSearch;
