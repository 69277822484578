import React from 'react';
import { createIcon, Icon } from '@chakra-ui/icon';

// Custom icons for Chakra UI
// Tip: Convert from SVG to JSX using https://transform.tools/
// https://next.chakra-ui.com/docs/components/icon#creating-icons-using-the-createicon-function

export const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    width: 8,
    height: 8,
  },
  path: (
    <path
      fill="currentColor"
      d="M29.6921 0H2.30786C1.0332 0 0 1.0332 0 2.30786V29.6921C0 30.9668 1.0332 32 2.30786 32H17.0938V19.625H12.9375V14.7812H17.0938V11.2166C17.0938 7.08276 19.6174 4.83276 23.3049 4.83276C25.071 4.83276 26.5889 4.96436 27.0312 5.02319V9.34375H24.4885C22.4822 9.34375 22.0938 10.2971 22.0938 11.6963V14.7812H26.8906L26.2656 19.625H22.0938V32H29.6921C30.9668 32 32 30.9668 32 29.6921V2.30786C32 1.0332 30.9668 0 29.6921 0V0Z"
    />
  ),
});

export const LinkedInIcon = createIcon({
  displayName: 'InstagramIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    width: 8,
    height: 8,
  },
  path: (
    <path
      fill="currentColor"
      d="M29.6921 0H2.30786C1.0332 0 0 1.0332 0 2.30786V29.6921C0 30.9668 1.0332 32 2.30786 32H29.6921C30.9668 32 32 30.9668 32 29.6921V2.30786C32 1.0332 30.9668 0 29.6921 0V0ZM11.3506 24.1875H7.45386V12.4641H11.3506V24.1875ZM9.40234 10.8633H9.37695C8.06934 10.8633 7.22363 9.96313 7.22363 8.83813C7.22363 7.68774 8.09521 6.8125 9.42822 6.8125C10.7612 6.8125 11.5815 7.68774 11.6069 8.83813C11.6069 9.96313 10.7612 10.8633 9.40234 10.8633ZM25.4014 24.1875H21.5051V17.9158C21.5051 16.3396 20.9409 15.2646 19.531 15.2646C18.4546 15.2646 17.8135 15.9897 17.5317 16.6897C17.4287 16.9402 17.4036 17.2903 17.4036 17.6406V24.1875H13.5071C13.5071 24.1875 13.5581 13.564 13.5071 12.4641H17.4036V14.124C17.9214 13.3252 18.8479 12.189 20.9153 12.189C23.479 12.189 25.4014 13.8645 25.4014 17.4653V24.1875Z"
    />
  ),
});

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    width: 8,
    height: 8,
  },
  path: (
    <g>
      <g clipPath="url(#clip0)">
        <path
          d="M2.30786 0H29.6921C30.9668 0 32 1.0332 32 2.30786V29.6921C32 30.9668 30.9668 32 29.6921 32H2.30786C1.0332 32 0 30.9668 0 29.6921V2.30786C0 1.0332 1.0332 0 2.30786 0Z"
          fill="currentColor"
        />
        <g clipPath="url(#clip1)">
          <path
            d="M26 10.2972C25.2642 10.6239 24.4733 10.8439 23.6433 10.943C24.4908 10.4355 25.1417 9.63138 25.4475 8.67305C24.655 9.14305 23.7767 9.48471 22.8417 9.66888C22.0942 8.87138 21.0267 8.37305 19.8467 8.37305C17.1975 8.37305 15.2508 10.8447 15.8492 13.4105C12.44 13.2397 9.41667 11.6064 7.3925 9.12388C6.3175 10.968 6.835 13.3805 8.66167 14.6022C7.99 14.5805 7.35667 14.3964 6.80417 14.0889C6.75917 15.9897 8.12167 17.768 10.095 18.1639C9.5175 18.3205 8.885 18.3572 8.24167 18.2339C8.76333 19.8639 10.2783 21.0497 12.075 21.083C10.35 22.4355 8.17667 23.0397 6 22.783C7.81583 23.9472 9.97333 24.6264 12.29 24.6264C19.9083 24.6264 24.2125 18.1922 23.9525 12.4214C24.7542 11.8422 25.45 11.1197 26 10.2972Z"
            fill="#1B1A1F"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect
            width="20"
            height="20"
            fill="currentColor"
            transform="translate(6 6.5)"
          />
        </clipPath>
      </defs>
    </g>
  ),
});

export const YouTubeIcon = createIcon({
  displayName: 'YouTubeIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    width: 8,
    height: 8,
  },
  path: (
    <>
      <g clipPath="url(#clip0)">
        <path
          d="M2.30786 0H29.6921C30.9668 0 32 1.0332 32 2.30786V29.6921C32 30.9668 30.9668 32 29.6921 32H2.30786C1.0332 32 0 30.9668 0 29.6921V2.30786C0 1.0332 1.0332 0 2.30786 0Z"
          fill="currentColor"
        />
        <path
          d="M26.5403 10.4984C26.2873 9.51499 25.5419 8.74051 24.5952 8.47768C22.8797 8 16 8 16 8C16 8 9.12042 8 7.40479 8.47768C6.4581 8.74051 5.7125 9.51499 5.45966 10.4984C5 12.2809 5 16 5 16C5 16 5 19.7189 5.45966 21.5016C5.7125 22.4851 6.4581 23.2594 7.40469 23.5225C9.12032 24 15.9999 24 15.9999 24C15.9999 24 22.8796 24 24.5951 23.5225C25.5418 23.2595 26.2872 22.4851 26.5402 21.5017C27 19.719 27 16.0001 27 16.0001C27 16.0001 27 12.281 26.502 10.4985"
          fill="#181823"
        />
        <path d="M13 19L19 16.0002L13 13V19Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});

export const InstagramIcon = createIcon({
  displayName: 'InstagramIcon',
  viewBox: '0 0 32 32',
  defaultProps: {
    height: 8,
    width: 8,
  },
  path: (
    <>
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H29C30.6569 0 32 1.34315 32 3V29C32 30.6569 30.6569 32 29 32H3C1.34315 32 0 30.6569 0 29V3Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M16 7.8025C18.67 7.8025 18.9867 7.8125 20.0417 7.86083C22.7517 7.98417 24.0175 9.27 24.1408 11.96C24.1892 13.0142 24.1983 13.3308 24.1983 16.0008C24.1983 18.6717 24.1883 18.9875 24.1408 20.0417C24.0167 22.7292 22.7542 24.0175 20.0417 24.1408C18.9867 24.1892 18.6717 24.1992 16 24.1992C13.33 24.1992 13.0133 24.1892 11.9592 24.1408C9.2425 24.0167 7.98333 22.725 7.86 20.0408C7.81167 18.9867 7.80167 18.6708 7.80167 16C7.80167 13.33 7.8125 13.0142 7.86 11.9592C7.98417 9.27 9.24667 7.98333 11.9592 7.86C13.0142 7.8125 13.33 7.8025 16 7.8025ZM16 6C13.2842 6 12.9442 6.01167 11.8775 6.06C8.24583 6.22667 6.2275 8.24167 6.06083 11.8767C6.01167 12.9442 6 13.2842 6 16C6 18.7158 6.01167 19.0567 6.06 20.1233C6.22667 23.755 8.24167 25.7733 11.8767 25.94C12.9442 25.9883 13.2842 26 16 26C18.7158 26 19.0567 25.9883 20.1233 25.94C23.7517 25.7733 25.775 23.7583 25.9392 20.1233C25.9883 19.0567 26 18.7158 26 16C26 13.2842 25.9883 12.9442 25.94 11.8775C25.7767 8.24917 23.7592 6.2275 20.1242 6.06083C19.0567 6.01167 18.7158 6 16 6V6ZM16 10.865C13.1642 10.865 10.865 13.1642 10.865 16C10.865 18.8358 13.1642 21.1358 16 21.1358C18.8358 21.1358 21.135 18.8367 21.135 16C21.135 13.1642 18.8358 10.865 16 10.865ZM16 19.3333C14.1592 19.3333 12.6667 17.8417 12.6667 16C12.6667 14.1592 14.1592 12.6667 16 12.6667C17.8408 12.6667 19.3333 14.1592 19.3333 16C19.3333 17.8417 17.8408 19.3333 16 19.3333ZM21.3383 9.4625C20.675 9.4625 20.1375 10 20.1375 10.6625C20.1375 11.325 20.675 11.8625 21.3383 11.8625C22.0008 11.8625 22.5375 11.325 22.5375 10.6625C22.5375 10 22.0008 9.4625 21.3383 9.4625Z"
          fill="#181823"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </>
  ),
});

export const FormalIcon = createIcon({
  displayName: 'FormalIcon',
  path: (
    <>
      <path
        d="M1.19734 10.8047H25.7954C26.9866 10.8047 27.4506 9.2514 26.4636 8.60698L14.1787 0.197449C13.7725 -0.0658164 13.2496 -0.0658164 12.8423 0.197449L0.529152 8.60698C-0.458936 9.28117 0.0356745 10.8047 1.19734 10.8047Z"
        fill="#004246"
      />
      <path
        d="M4.27649 12.0925C4.07343 12.0925 3.92822 12.2688 3.92822 12.4439L3.92822 22.6998C3.92822 22.9047 4.10293 23.0512 4.27649 23.0512H7.50071C7.70377 23.0512 7.84898 22.8749 7.84898 22.6998V12.4439C7.84898 12.239 7.67427 12.0925 7.50071 12.0925H4.27649Z"
        fill="#004246"
      />
      <path
        d="M11.9113 12.0925C11.7082 12.0925 11.563 12.2688 11.563 12.4439V22.6998C11.563 22.9047 11.7377 23.0512 11.9113 23.0512H15.1355C15.3385 23.0512 15.4837 22.8749 15.4837 22.6998V12.4439C15.4837 12.239 15.309 12.0925 15.1355 12.0925H11.9113Z"
        fill="#108F97"
      />
      <path
        d="M19.5214 12.0927C19.3183 12.0927 19.1731 12.2689 19.1731 12.444V22.6999C19.1731 22.9048 19.3478 23.0513 19.5214 23.0513H22.7456C22.9486 23.0513 23.0938 22.8751 23.0938 22.6999V12.444C23.0938 12.2392 22.9191 12.0927 22.7456 12.0927H19.5214Z"
        fill="#004246"
      />
      <path
        d="M26.0593 24.3755H0.967764C0.64784 24.3755 0.357422 24.6388 0.357422 24.9913L0.357422 27.2474C0.357422 27.5702 0.618345 27.8632 0.967764 27.8632H26.0311C26.351 27.8632 26.6414 27.5999 26.6414 27.2474V24.9913C26.6403 24.6399 26.3794 24.3755 26.0595 24.3755H26.0593Z"
        fill="#004246"
      />
    </>
  ),
});

export const InformalIcon = createIcon({
  displayName: 'InformalIcon',
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0001 0H2C0.895431 0 0 0.89543 0 2V23C0 24.1046 0.895431 25 2 25H17C18.1046 25 19 24.1046 19 23V6.00002H13.0001V0Z"
        fill="#393189"
      />
      <path d="M14 0L19 5H14V0Z" fill="#665CD0" />
      <line
        x1="3.86842"
        y1="9.13158"
        x2="15.1316"
        y2="9.13158"
        stroke="white"
        strokeWidth="1.73684"
        strokeLinecap="round"
      />
      <line
        x1="3.94728"
        y1="13.3684"
        x2="10.0262"
        y2="13.3684"
        stroke="white"
        strokeWidth="1.73684"
        strokeLinecap="round"
      />
      <line
        x1="3.94728"
        y1="17.7106"
        x2="12.6315"
        y2="17.7106"
        stroke="white"
        strokeWidth="1.73684"
        strokeLinecap="round"
      />
    </>
  ),
});

export const ChatIcon = createIcon({
  displayName: 'ChatIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7756 0.152344C22.0128 0.152344 23.8264 1.96594 23.8264 4.20312V16.3555C23.8264 18.5927 22.0128 20.4062 19.7756 20.4062H4.31967L1.32583 23.6139C0.905996 24.0638 0.15229 23.7667 0.15229 23.1514V14.4001C0.15229 14.3148 0.168066 14.2331 0.196859 14.1578V4.20313C0.196859 1.96594 2.01045 0.152344 4.24764 0.152344H19.7756Z"
      fill="#26B0B8"
    />
  ),
});
