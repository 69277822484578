export const isExternalLink = (link: string, whitelist = ['amica.gov.au']) => {
  // If host name exists in the url then return false
  if (typeof window !== 'undefined') {
    const hostNameRegEx = new RegExp(`${window.location.hostname}`, 'i');
    if (hostNameRegEx.test(link)) {
      return false;
    }
  }

  //disrgard if white liist
  const isInWhiteList = whitelist.some((item) => {
    const reg = new RegExp(item, 'i');
    return reg.test(link);
  });

  if (isInWhiteList) {
    return false;
  }
  // Check whether http:// or https:// exists
  return /(http(s?)):\/\//i.test(link);
};

export const runQuickExit = ({
  newTabUrl,
  currentTabUrl,
}: {
  newTabUrl: string;
  currentTabUrl: string;
}) => {
  if (typeof window !== 'undefined') {
    // Get away right now
    window.open(newTabUrl, '_newtab');
    // Replace current site with another benign site
    window.location.replace(currentTabUrl);
  }
};

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export const clampDimensions = ({
  width,
  height,
  clampWidth,
}: {
  width: number | string;
  height: number | string;
  clampWidth: number;
}) => {
  const ratio = Number(height) / Number(width);
  return {
    width: clamp(Number(width), 0, clampWidth).toFixed(0),
    height: clamp(Number(height), 0, clampWidth * ratio).toFixed(0),
  };
};

export const onClickAnchorLink = (id: string, offset = 180) => {
  if (typeof window !== 'undefined') {
    const anchor = document.getElementById(id);
    if (!anchor) return;
    const anchorTop = anchor.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: anchorTop - offset, behavior: 'smooth' });
    anchor.focus({ preventScroll: true });
  }
};
