import React from 'react';

import { Container, Flex, IconButton } from '@chakra-ui/react';
import type { FlexProps, LinkProps } from '@chakra-ui/react';

import { Link } from '@/theme/chakra-elements';

import {
  FacebookIcon,
  TwitterIcon,
  YouTubeIcon,
  InstagramIcon,
  LinkedInIcon,
} from '@/theme/icons';

type Props = FlexProps;

const FooterContainer: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Flex
      as="footer"
      backgroundColor="custom.neutral.800"
      color="custom.neutral.100"
      textStyle="body 2"
      flexShrink={0}
      justifyContent="center"
      width="100%"
      textAlign="left"
      layerStyle="footer"
      {...rest}
    >
      <Container maxWidth="container.xl">{children}</Container>
    </Flex>
  );
};

const NavigationLink: React.FC<LinkProps> = ({
  children,
  href = '/',
  ...rest
}) => (
  <Link
    alignSelf="start"
    variant="navigation"
    href={href}
    colorScheme="amica-light"
    data-gtm-id="navigation-link"
    {...rest}
  >
    {children}
  </Link>
);

type SocialButtonProps = {
  href?: string;
};

const ButtonFacebook: React.VFC<SocialButtonProps> = ({ href }) => (
  <Link href={href} data-gtm-id="button-facebook">
    <IconButton
      aria-label="facebook"
      icon={<FacebookIcon />}
      colorScheme="none"
      color="white"
      _hover={{ color: 'custom.primary.400' }}
      size="icon"
      width={8}
      height={8}
    />
  </Link>
);

const ButtonTwitter: React.VFC<SocialButtonProps> = ({ href }) => (
  <Link href={href} data-gtm-id="button-twitter">
    <IconButton
      aria-label="twitter"
      icon={<TwitterIcon />}
      color="white"
      _hover={{ color: 'custom.primary.400' }}
      colorScheme="none"
      size="icon"
      width={8}
      height={8}
    />
  </Link>
);

const ButtonLinkedIn: React.VFC<SocialButtonProps> = ({ href }) => (
  <Link href={href} data-gtm-id="button-linkedin">
    <IconButton
      aria-label="youtube"
      icon={<LinkedInIcon />}
      size="icon"
      color="white"
      _hover={{ color: 'custom.primary.400' }}
      colorScheme="none"
      width={8}
      height={8}
    />
  </Link>
);

const ButtonYoutube: React.VFC<SocialButtonProps> = ({ href }) => (
  <Link href={href} data-gtm-id="button-youtube">
    <IconButton
      aria-label="youtube"
      icon={<YouTubeIcon />}
      size="icon"
      color="white"
      _hover={{ color: 'custom.primary.400' }}
      colorScheme="none"
      width={8}
      height={8}
    />
  </Link>
);

const ButtonInstagram: React.VFC<SocialButtonProps> = ({ href }) => (
  <Link href={href} data-gtm-id="button-instagram">
    <IconButton
      aria-label="instagram"
      icon={<InstagramIcon />}
      size="icon"
      color="white"
      _hover={{ color: 'custom.primary.400' }}
      colorScheme="none"
      width={8}
      height={8}
    />
  </Link>
);

export {
  FooterContainer,
  NavigationLink,
  ButtonFacebook,
  ButtonTwitter,
  ButtonLinkedIn,
  ButtonYoutube,
  ButtonInstagram,
};
