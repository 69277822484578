import React from 'react';
import { Box } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import Footer from '../Footer';
import Header from '../Header';
import { SiteSettingsType } from '@/api';

type Props = {
  siteSettings: SiteSettingsType;
} & FlexProps;

const Page: React.FC<Props> = ({ children, siteSettings = {}, ...rest }) => {
  return (
    <Flex direction="column" minHeight="100%" {...rest}>
      <Header siteSettings={siteSettings} />
      <Box as="main" zIndex={1} flex={1}>
        {children}
      </Box>
      <Footer siteSettings={siteSettings} />
    </Flex>
  );
};

export default Page;
