import React from 'react';

import type { ButtonProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';

export type ButtonLoginProps = { href?: string } & ButtonProps;

const ButtonLogin: React.FC<ButtonLoginProps> = ({
  children,
  href,
  ...rest
}) => (
  <LinkButton
    href={href}
    data-gtm-id="button-login"
    textStyle="button"
    variant="ghost"
    {...rest}
  >
    {children}
  </LinkButton>
);

export default ButtonLogin;
