import React from 'react';
import { Grid, GridItem, Text, Box, Stack } from '@chakra-ui/react';
import type { FlexProps } from '@chakra-ui/react';

import { navigationLinks } from '@/constants';
import { Image, Link } from '@/theme/chakra-elements';

import { SiteSettingsType } from '@/api';
import { SectionLeftRight } from '@/components/layout';
import { ButtonBackToTop, ButtonLogin, ButtonRegister } from '@/components/ui';

import LogoImageSVG from '@/images/logo.svg';
import nlaWhite from '@/images/nla_white.png';
import portableLogo from '@/images/portable-logo.svg';

import {
  FooterContainer,
  NavigationLink,
  ButtonFacebook,
  ButtonYoutube,
  ButtonInstagram,
  ButtonLinkedIn,
} from './FooterComponents';

type Props = {
  siteSettings: SiteSettingsType;
} & FlexProps;

const Footer: React.FC<Props> = ({ siteSettings = {}, ...rest }) => {
  const {
    loginLinkText,
    loginLink,
    registerLinkText,
    registerLink,
    facebookLink,
    linkedinLink,
    youtubeLink,
    instagramLink,
  } = siteSettings;

  const { footer: footerData } = navigationLinks;

  return (
    <FooterContainer {...rest} data-gtm-id="footer">
      <SectionLeftRight layout="right" paddingBottom={8} spacing={10}>
        {[
          <Box key="backToTop">
            <ButtonBackToTop />
          </Box>,
          <Box key="logo">
            <Link data-gtm-id="footer-logo-link" aria-label="Home Link">
              <Image
                alt=""
                src={LogoImageSVG}
                height={16}
                width={62}
                quality={100}
                priority
              />
            </Link>
          </Box>,
        ]}
      </SectionLeftRight>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={6}
        display={{ base: 'block', xl: 'grid' }}
      >
        <GridItem colSpan={5}>
          <Text
            textStyle="body 1"
            paddingBottom={{ base: 8, xl: 0 }}
            maxWidth="480px"
          >
            National Legal Aid acknowledges the Traditional Custodians of
            country throughout Australia and their connections to land, sea and
            community. We pay our respect to their Elders past and present and
            extend that respect to all Aboriginal and Torres Strait Islander
            peoples today.
          </Text>
        </GridItem>
        <GridItem colStart={7} colSpan={6}>
          <Stack
            spacing={{ base: 10, xl: 8 }}
            marginBottom={12}
            direction={{ base: 'column', xl: 'row' }}
          >
            <Stack
              spacing={{ base: 3, xl: 4 }}
              width={{ base: 'initial', xl: '33.33%' }}
            >
              {footerData.section1.map(({ text, url }, index) => (
                <NavigationLink
                  key={text}
                  textStyle={index === 0 ? 'button' : 'button 2'}
                  href={url}
                >
                  {text}
                </NavigationLink>
              ))}
            </Stack>
            <Stack
              spacing={{ base: 3, xl: 4 }}
              width={{ base: 'initial', xl: '33.33%' }}
            >
              {footerData.section2.map(({ text, url }, index) => (
                <NavigationLink
                  key={text}
                  textStyle={index === 0 ? 'button' : 'button 2'}
                  href={url}
                >
                  {text}
                </NavigationLink>
              ))}
            </Stack>
            <Stack
              spacing={{ base: 3, xl: 4 }}
              width={{ base: 'initial', xl: '33.33%' }}
            >
              {footerData.section3.map(({ text, url }, index) => (
                <NavigationLink
                  key={text}
                  textStyle={index === 0 ? 'button' : 'button 2'}
                  href={url}
                >
                  {text}
                </NavigationLink>
              ))}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={4}
            display={{ base: 'flex', xl: 'none' }}
            marginBottom={12}
          >
            <ButtonRegister href={registerLink}>
              {registerLinkText}
            </ButtonRegister>
            <ButtonLogin
              colorScheme="amica-black"
              variant="solid"
              textStyle="button 2"
              size="small"
              href={loginLink}
            >
              {loginLinkText}
            </ButtonLogin>
          </Stack>
        </GridItem>
        <GridItem colSpan={5}>
          <Stack
            spacing={12}
            marginBottom={{ base: 12, xl: 0 }}
            direction={{ base: 'column', xl: 'row' }}
            alignItems={{ base: 'normal', xl: 'flex-end' }}
          >
            <Link
              href="https://www.nationallegalaid.org/"
              aria-label="National Legal Aid Website"
              data-gtm-id="image-link-nla"
              variant="navigation"
              colorScheme="amica-light"
            >
              <Image
                quality={100}
                src={nlaWhite}
                width={117}
                height={49}
                alt=""
              />
              <Text marginTop={4} maxWidth={40}>
                Developed by National Legal Aid
              </Text>
            </Link>
            <Link
              href="https://www.portable.com.au/"
              aria-label="Portable Website"
              data-gtm-id="image-link-portable"
              variant="navigation"
              colorScheme="amica-light"
            >
              <Box pb="3px">
                <Image
                  src={portableLogo}
                  height={15}
                  width={84}
                  alt="Portable logo"
                  quality={100}
                />
              </Box>
              <Text marginTop={4} maxWidth={40}>
                In partnership with Portable
              </Text>
            </Link>
            <Box display={{ base: 'block', xl: 'none' }}>
              <Stack direction="row" spacing={3} marginBottom={6}>
                <ButtonFacebook href={facebookLink} />
                <ButtonLinkedIn href={linkedinLink} />
                <ButtonYoutube href={youtubeLink} />
                <ButtonInstagram href={instagramLink} />
              </Stack>
              <Stack direction="row" spacing={{ base: 4, md: 6 }}>
                {footerData.section4.map(({ text, url }) => (
                  <NavigationLink key={text} textStyle={'button 2'} href={url}>
                    {text}
                  </NavigationLink>
                ))}
              </Stack>
            </Box>
          </Stack>
        </GridItem>
        <GridItem colStart={7} colSpan={6}>
          <Box display={{ base: 'none', xl: 'block' }}>
            <Stack direction="row" spacing={4} marginBottom={8}>
              <ButtonRegister href={registerLink}>
                {registerLinkText}
              </ButtonRegister>
              <ButtonLogin
                colorScheme="amica-black"
                variant="solid"
                textStyle="button 2"
                size="small"
                href={loginLink}
              >
                {loginLinkText}
              </ButtonLogin>
            </Stack>
            <Stack direction="row" spacing={3} marginBottom={6}>
              <ButtonFacebook href={facebookLink} />
              <ButtonLinkedIn href={linkedinLink} />
              <ButtonYoutube href={youtubeLink} />
              <ButtonInstagram href={instagramLink} />
            </Stack>
            <Stack direction="row" spacing={{ base: 4, md: 6 }}>
              {footerData.section4.map(({ text, url }) => (
                <NavigationLink key={text} textStyle={'button 2'} href={url}>
                  {text}
                </NavigationLink>
              ))}
            </Stack>
          </Box>
        </GridItem>
      </Grid>
    </FooterContainer>
  );
};

export default Footer;
