export const navigationLinks = {
  search: { text: 'Search', url: '/search' },
  header: {
    navigation: [
      { text: 'How it works', url: '/how-it-works' },
      { text: 'Info about separating', url: '/info-about-separating' },
      { text: 'Common questions', url: '/common-questions' },
      { text: 'Help and support', url: '/help-and-support' },
      { text: 'Blog', url: '/blog' },
    ],
  },
  footer: {
    section1: [
      { text: 'How it works', url: '/how-it-works' },
      { text: 'Money and property', url: '/how-it-works/money-and-property' },
      { text: 'Parenting', url: '/how-it-works/parenting' },
      { text: 'Pricing', url: '/how-it-works/pricing' },
    ],
    section2: [
      { text: 'Help and support', url: '/help-and-support' },
      { text: 'Common questions', url: '/common-questions' },
      {
        text: 'Is amica right for me',
        url: '/is-amica-right-for-me',
      },
    ],
    section3: [
      {
        text: 'Info about separating',
        url: '/info-about-separating',
      },
      {
        text: 'De facto relationships',
        url: '/info-about-separating/de-facto-relationships',
      },
      {
        text: 'Money and property',
        url: '/info-about-separating/money-and-property',
      },
      {
        text: 'Parenting arrangements',
        url: '/info-about-separating/parenting-arrangements',
      },
      {
        text: 'Family violence help',
        url: '/info-about-separating/family-violence-help',
      },
      {
        text: 'Help for kids',
        url: '/info-about-separating/help-for-kids',
      },
      {
        text: 'Information for Indigenous Australians',
        url: '/info-about-separating/information-for-indigenous-australians',
      },
    ],
    section4: [
      { text: 'Terms and conditions', url: '/terms-of-use' },
      { text: 'Privacy', url: '/privacy-statement' },
      { text: 'Media', url: '/media-enquiries' },
      { text: 'Blog', url: '/blog' },
    ],
  },
};
