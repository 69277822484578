import React from 'react';
import { Stack, useBreakpointValue } from '@chakra-ui/react';
import type { StackProps } from '@chakra-ui/react';
import type { LayoutType } from '@/components/ui';

export type SectionLeftRightProps = {
  layout: LayoutType;
  children: React.ReactElement[];
  options?: { breakpoint: 'xl' | 'lg' | 'md' | 'sm' };
} & StackProps;

/**
 * Section Left Right Layout
 * Will switch the content 'left' + 'righ't at breakpoint
 * Note: Want to avoid using flex direction = 'row-reverse' as its not accessible for
 * sreen readers. ref: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Ordering_Flex_Items.
 **/

const SectionLeftRight: React.FC<SectionLeftRightProps> = ({
  layout = 'left',
  children,
  ...rest
}) => {
  const check = useBreakpointValue({ lg: 'switch' });

  let [left, right] = children;

  if (check === 'switch' && layout === 'right') {
    [left, right] = [right, left];
  }

  return (
    <Stack
      display="flex"
      spacing={{ base: 24, lg: 8 }}
      justifyContent={{ base: 'center', lg: 'space-between' }}
      width="100%"
      direction={{ base: 'column', lg: 'row' }}
      {...rest}
    >
      {left}
      {right}
    </Stack>
  );
};

export default SectionLeftRight;
