import React from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import MarkdownToJSX from 'markdown-to-jsx';

import { ListItem, Text, OrderedList, UnorderedList } from '@chakra-ui/react';
import { Link } from '@/theme/chakra-elements';

import theme from '@/theme/_generated/index';

type Props = {
  body: string;
  headingsFont?: string;
  overrides?: {
    [key: string]: {
      component: any;
      props: {
        [key: string]: string | number;
      };
    };
  };
} & BoxProps;

export const defaultOverrides = (headingsFont = '') => ({
  p: {
    component: Text,
    props: {
      textStyle: 'body 1',
      mb: theme.space[4],
      maxWidth: 'content.lg',
    },
  },
  a: {
    component: Link,
    props: { textStyle: 'link' },
  },
  h1: {
    component: Text,
    props: {
      textStyle: headingsFont === 'Founders' ? 'h1 conversational' : 'tite',
      as: 'h1',
      maxWidth: 'content.lg',
    },
  },
  h2: {
    component: Text,
    props: {
      textStyle: headingsFont === 'Founders' ? 'h1 conversational' : 'h2',
      as: 'h2',
      mb: theme.space[6],
      mt: theme.space[10],
      maxWidth: { base: 'content.md', lg: 'content.lg' },
    },
  },
  h3: {
    component: Text,
    props: {
      textStyle: 'h3',
      as: 'h3',
      mb: theme.space[6],
      mt: theme.space[10],
      maxWidth: { base: 'content.md', lg: 'content.lg' },
    },
  },
  h4: {
    component: Text,
    props: {
      textStyle: 'h4',
      as: 'h4',
      mb: theme.space[4],
      mt: theme.space[10],
      maxWidth: { base: 'content.md', lg: 'content.lg' },
    },
  },
  ol: {
    component: OrderedList,
    props: {
      paddingLeft: theme.space[4],
    },
  },
  ul: {
    component: UnorderedList,
    props: {
      paddingLeft: theme.space[4],
      marginBottom: theme.space[4],
    },
  },
  li: {
    component: ListItem,
    props: {
      textStyle: 'body 1',
    },
  },
  table: {
    props: {
      style: {
        width: '100%',
      },
    },
  },
  td: {
    props: {
      style: {
        borderTopWidth: '1px',
        borderColor: theme.colors.custom.neutral[300],
        paddingRight: theme.space[4],
        paddingTop: theme.space[6],
        paddingBottom: theme.space[6],
        textStyle: 'body 2',
      },
    },
  },
  th: {
    props: {
      style: {
        borderTopWidth: '0px',
        textAlign: 'left',
        paddingRight: theme.space[4],
        paddingTop: theme.space[6],
        paddingBottom: theme.space[6],
        textStyle: 'body 1 bold',
      },
    },
  },
  strong: {
    props: {
      style: {
        textStyle: 'body 1 bold',
      },
    },
  },
});

const Markdown: React.FC<Props> = ({
  body,
  headingsFont,
  overrides = {},
  ...rest
}) => {
  if (!body) {
    return null;
  }

  return (
    <Box
      sx={{
        sup: {
          fontSize: '0.65em',
          lineHeight: '1em',
          verticalAlign: 'middle',
          marginLeft: '-0.2em',
        },
      }}
      {...rest}
    >
      <MarkdownToJSX
        options={{
          slugify: (string) => string,
          overrides: { ...defaultOverrides(headingsFont), ...overrides },
        }}
      >
        {body}
      </MarkdownToJSX>
    </Box>
  );
};

export default Markdown;
