import React from 'react';

import { ArrowUpIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';

export type Props = ButtonProps;

const goToTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

const ButtonBackToTop: React.FC<Props> = ({
  children = 'Back to top',
  ...rest
}) => {
  return (
    <Button
      rightIcon={<ArrowUpIcon />}
      colorScheme="amica-black"
      onClick={goToTop}
      data-gtm-id="button-back-to-top"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ButtonBackToTop;
