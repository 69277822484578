import React from 'react';

import { ButtonProps } from '@chakra-ui/react';
import { LinkButton } from '@/theme/chakra-elements';

export type ButtonRegisterProps = { href?: string } & ButtonProps;

const ButtonRegister: React.FC<ButtonRegisterProps> = ({
  children,
  href,
  ...rest
}) => (
  <LinkButton href={href} data-gtm-id="button-register" {...rest}>
    {children}
  </LinkButton>
);

export default ButtonRegister;
